import {PageNames} from "../pages/pages";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Footer() {
  const {t} = useTranslation();

  return (
    <footer id="footer" className="bg-primary-dark pt-10 pb-10 text-white text-sm">
      <div className="container">
        <div className="text-center grid grid-cols-1 justify-items-center gap-6 lg:grid-cols-6 lg:gap-0">

          <div className="lg:col-span-2 lg:col-start-3">
            <img
              className="mb-7 w-28"
              src="rentattest-white.png"
              alt="rentattest logo"
            />
          </div>

          <div className="flex flex-col justify-between items-center lg:items-end lg:justify-self-end lg:col-span-2">
            <div className="mt-2">
              <a className="text-neutral-white block lg:text-right text-center mb-5"
                 href="mailto:info@rentattest.be">info@rentattest.be</a>
              <a className="hover:text-primary-cadet-blue block lg:text-right text-center text-neutral-white"
                 href={PageNames.PRIVACY_POLICY}
              >
                {t('footer.privacy-policy')}
              </a>
              <a className="hover:text-primary-cadet-blue block lg:text-right text-center text-neutral-white"
                 href={PageNames.COOKIE_POLICY}
              >
                {t("footer.cookie-policy")}
              </a>
              <a className="hover:text-primary-cadet-blue block lg:text-right text-center text-neutral-white"
                 href={PageNames.ALGEMENE_VOORWAARDEN}
              >
                {t("footer.terms-and-conditions")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
