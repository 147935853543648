import React from "react";
import {useTranslation} from "react-i18next";

export function Pricing() {
  const gradient = "rentattest-gradient"
  return (
    <div id="price" className="container flex flex-col items-center">
      <section
        className="flex flex-col items-center py-6 w-full lg:flex-row lg:justify-center max-w-md ">
        <PricingItem price={95} style={gradient + " text-white"} primary={true}/>
      </section>
    </div>
  );
}

function PricingItem(props: {
  style?: string,
  price: number,
  primary?: boolean,
}) {
  const {t} = useTranslation();
  const {style, price, primary} = props;
  const pricingStyle = primary ? "mt-2 border-gray-100" : "border-gray-200";
  const nonPrimaryArticleStyle = "lg:w-custom rounded-lg text-center w-5/5 lg:w-4/5 mb-8 px-6 py-10 lg:px-4 bg-white text-primary-dark"
  const primaryArticleStyle = "lg:w-custom rounded-lg text-center w-5/5 lg:w-4/5 mb-8 px-6 py-12 text-white"
  const articleStyle = primary ? primaryArticleStyle : nonPrimaryArticleStyle;
  return <article className={`${articleStyle} ${style}`}>
    <h5 className="text-base font-bold">{t('pricing.top')}</h5>
    <h2 className={`pb-4 flex justify-center font-bold border-b ${pricingStyle}`}>
      <span className="mt-6 mr-1 text-3xl">€</span>
      <span className="text-6xl">{price}</span>
    </h2>
    <span className="text-sm">{t('pricing.vat')}</span>
    <p className="text-sm font-bold pt-4">{t('pricing.sub')}</p>
  </article>;
}
